<script lang="ts">
import idPageInit from '~/packages/datakode/nuxt-datakode-utils/utils/idPageInit.vue'

export default {
    name: 'MapServicePage',
    extends: idPageInit,
}
</script>

<template>
    <ModalMiddle
        ref="modalParent"
        :max-width="800"
        :saving="saving"
        :save-text="isCreatePath ? 'Ajouter le service' : 'Modifier le service'"
        custom-button-color="primary"
        :custom-button-block="true"
        :default-url="defaultUrl"
        :title-modal="titleModal"
        :is-form="isForm"
        :data="fetchedData"

        :hide-default-actions="!isForm"
        :hide-top-buttons="true"

        :disable-save-button="!$refs.display?.pageRef?.hasServiceValid ?? true"

        @save="save"
        @close-modal="closeModal"
    >
        <template #customContentTop>
            <v-btn
                v-if="$permissionCheck(`${permissionName}_update`) && !isForm && meta?.editable"
                prepend-icon="mdi-square-edit-outline"
                class="mb-4"
                color="primary"
                size="small"
                @click.prevent="$refs.modalParent?.redirectEdit()"
            >
                Modifier
            </v-btn>
        </template>
        <template #content>
            <v-form
                ref="form"
                fast-fail
                @submit.prevent
            >
                <NuxtPage
                    ref="display"
                    :loading="loading"
                    :data="fetchedData"
                    :rules="rules"
                    :is-form="isForm"
                    :translation-name="translationName"
                />
            </v-form>
        </template>
    </ModalMiddle>
</template>
