import { default as formulaire5tSmkcUNccMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/applications/[k]/formulaire.vue?macro=true";
import { default as indexrfrMq2KSDMMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/applications/index.vue?macro=true";
import { default as connexionxHX1p8KdiLMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/connexion.vue?macro=true";
import { default as formulaire7iECvUETS0Meta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/couches-cartographiques/[k]/formulaire.vue?macro=true";
import { default as indexfGlVwH3i3CMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/couches-cartographiques/[k]/index.vue?macro=true";
import { default as _91k_93kW38wew7BZMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/couches-cartographiques/[k].vue?macro=true";
import { default as couches_45cartographiquesRmjdx4yXarMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/couches-cartographiques.vue?macro=true";
import { default as demandes_45acces5hVTZ2LqnRMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/demandes-acces.vue?macro=true";
import { default as donnees_45personnellesfYInGyCOJmMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/donnees-personnelles.vue?macro=true";
import { default as index0M40QJZGArMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/index.vue?macro=true";
import { default as mentions_45legalesAKyLyfnky9Meta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/mentions-legales.vue?macro=true";
import { default as _91token_93wBn9rZcSQPMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/mot-de-passe-oublie/[token].vue?macro=true";
import { default as index1mqIWP2TjNMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/mot-de-passe-oublie/index.vue?macro=true";
import { default as mot_45de_45passe_45oublie0Hcw5V3VqGMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/mot-de-passe-oublie.vue?macro=true";
import { default as formulaireeCAjpUWfsUMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/pages/[k]/formulaire.vue?macro=true";
import { default as indexabY4nAprZKMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/pages/[k]/index.vue?macro=true";
import { default as _91k_93pfGIz4ogERMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/pages/[k].vue?macro=true";
import { default as pagesy0S6Rf3TPGMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/pages.vue?macro=true";
import { default as indexRWzc3mzMhMMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/profil/index.vue?macro=true";
import { default as profilA5dKSJL2amMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/profil.vue?macro=true";
import { default as formulaireQn8b0V2XwgMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/services-cartographiques/[k]/formulaire.vue?macro=true";
import { default as index9TASuFv1m7Meta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/services-cartographiques/[k]/index.vue?macro=true";
import { default as _91k_93UaRvyX89mRMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/services-cartographiques/[k].vue?macro=true";
import { default as services_45cartographiquesR2zSIYAeL6Meta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/services-cartographiques.vue?macro=true";
import { default as formulaireElgrsQ07RDMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/structures/[k]/formulaire.vue?macro=true";
import { default as indexjswFjyM7Z0Meta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/structures/[k]/index.vue?macro=true";
import { default as _91k_93JqCn64hRNjMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/structures/[k].vue?macro=true";
import { default as structuresXxFAxE8vxRMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/structures.vue?macro=true";
import { default as formulairecK8cPdprvhMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/utilisateurs/[k]/formulaire.vue?macro=true";
import { default as indexMN4zjdL8KFMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/utilisateurs/[k]/index.vue?macro=true";
import { default as _91k_93XJ5MLdUOLxMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/utilisateurs/[k].vue?macro=true";
import { default as utilisateursomJeuTVdpsMeta } from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/utilisateurs.vue?macro=true";
export default [
  {
    name: "applications-k-formulaire",
    path: "/applications/:k()/formulaire",
    meta: formulaire5tSmkcUNccMeta || {},
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/applications/[k]/formulaire.vue").then(m => m.default || m)
  },
  {
    name: "applications",
    path: "/applications",
    meta: indexrfrMq2KSDMMeta || {},
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/applications/index.vue").then(m => m.default || m)
  },
  {
    name: "connexion",
    path: "/connexion",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/connexion.vue").then(m => m.default || m)
  },
  {
    name: "couches-cartographiques",
    path: "/couches-cartographiques",
    meta: couches_45cartographiquesRmjdx4yXarMeta || {},
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/couches-cartographiques.vue").then(m => m.default || m),
    children: [
  {
    name: _91k_93kW38wew7BZMeta?.name,
    path: ":k()",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/couches-cartographiques/[k].vue").then(m => m.default || m),
    children: [
  {
    name: "couches-cartographiques-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/couches-cartographiques/[k]/formulaire.vue").then(m => m.default || m)
  },
  {
    name: "couches-cartographiques-k",
    path: "",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/couches-cartographiques/[k]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "demandes-acces",
    path: "/demandes-acces",
    meta: demandes_45acces5hVTZ2LqnRMeta || {},
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/demandes-acces.vue").then(m => m.default || m)
  },
  {
    name: "donnees-personnelles",
    path: "/donnees-personnelles",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/donnees-personnelles.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: mot_45de_45passe_45oublie0Hcw5V3VqGMeta?.name,
    path: "/mot-de-passe-oublie",
    meta: mot_45de_45passe_45oublie0Hcw5V3VqGMeta || {},
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/mot-de-passe-oublie.vue").then(m => m.default || m),
    children: [
  {
    name: "mot-de-passe-oublie-token",
    path: ":token()",
    meta: _91token_93wBn9rZcSQPMeta || {},
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/mot-de-passe-oublie/[token].vue").then(m => m.default || m)
  },
  {
    name: "mot-de-passe-oublie",
    path: "",
    meta: index1mqIWP2TjNMeta || {},
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/mot-de-passe-oublie/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "pages",
    path: "/pages",
    meta: pagesy0S6Rf3TPGMeta || {},
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/pages.vue").then(m => m.default || m),
    children: [
  {
    name: _91k_93pfGIz4ogERMeta?.name,
    path: ":k()",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/pages/[k].vue").then(m => m.default || m),
    children: [
  {
    name: "pages-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/pages/[k]/formulaire.vue").then(m => m.default || m)
  },
  {
    name: "pages-k",
    path: "",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/pages/[k]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: profilA5dKSJL2amMeta?.name,
    path: "/profil",
    meta: profilA5dKSJL2amMeta || {},
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/profil.vue").then(m => m.default || m),
    children: [
  {
    name: "profil",
    path: "",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/profil/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "services-cartographiques",
    path: "/services-cartographiques",
    meta: services_45cartographiquesR2zSIYAeL6Meta || {},
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/services-cartographiques.vue").then(m => m.default || m),
    children: [
  {
    name: _91k_93UaRvyX89mRMeta?.name,
    path: ":k()",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/services-cartographiques/[k].vue").then(m => m.default || m),
    children: [
  {
    name: "services-cartographiques-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/services-cartographiques/[k]/formulaire.vue").then(m => m.default || m)
  },
  {
    name: "services-cartographiques-k",
    path: "",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/services-cartographiques/[k]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "structures",
    path: "/structures",
    meta: structuresXxFAxE8vxRMeta || {},
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/structures.vue").then(m => m.default || m),
    children: [
  {
    name: _91k_93JqCn64hRNjMeta?.name,
    path: ":k()",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/structures/[k].vue").then(m => m.default || m),
    children: [
  {
    name: "structures-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/structures/[k]/formulaire.vue").then(m => m.default || m)
  },
  {
    name: "structures-k",
    path: "",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/structures/[k]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "utilisateurs",
    path: "/utilisateurs",
    meta: utilisateursomJeuTVdpsMeta || {},
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/utilisateurs.vue").then(m => m.default || m),
    children: [
  {
    name: _91k_93XJ5MLdUOLxMeta?.name,
    path: ":k()",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/utilisateurs/[k].vue").then(m => m.default || m),
    children: [
  {
    name: "utilisateurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/utilisateurs/[k]/formulaire.vue").then(m => m.default || m)
  },
  {
    name: "utilisateurs-k",
    path: "",
    component: () => import("/builds/9542779116/pildom/APPS/NUXT_ADMIN/pages/utilisateurs/[k]/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]