<script lang="ts">
import idPageInit from '~/packages/datakode/nuxt-datakode-utils/utils/idPageInit.vue'

export default {
    extends: idPageInit,
}
</script>

<template>
    <ModalMiddle
        ref="modalParent"
        :max-width="800"
        :saving
        :save-text="isCreatePath ? 'Ajouter la page' : 'Modifier la page'"
        custom-button-color="primary"
        custom-button-block
        :default-url
        :title-modal
        :is-form
        :data="fetchedData"
        :hide-top-buttons="true"
        :hide-default-actions="!isForm"

        @save="save"
        @close-modal="closeModal"
    >
    <template #customContentTop>
            <v-btn
                v-if="$permissionCheck(`${permissionName}_update`) && !isForm && meta?.editable"
                prepend-icon="mdi-square-edit-outline"
                class="mb-4"
                color="primary"
                size="small"
                @click.prevent="$refs.modalParent?.redirectEdit()"
            >
                Modifier
            </v-btn>
        </template>
        <template #content>
            <v-form
                ref="form"
                fast-fail
                @submit.prevent
            >
                <NuxtPage
                    ref="display"
                    :loading
                    :data="fetchedData"
                    :rules
                    :translation-name
                />
            </v-form>
        </template>
    </ModalMiddle>
</template>
