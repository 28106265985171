import revive_payload_client_mum5vOjUtA from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.31_encoding@0.1.13_eslint@8.57.0_io_f6286325491df43de002b93d43d737a4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pwr5MNdm3Z from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.31_encoding@0.1.13_eslint@8.57.0_io_f6286325491df43de002b93d43d737a4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Hue9VlnLeT from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.31_encoding@0.1.13_eslint@8.57.0_io_f6286325491df43de002b93d43d737a4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_oBYI1bUgai from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.31_encoding@0.1.13_eslint@8.57.0_io_f6286325491df43de002b93d43d737a4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_S1y99RSVBq from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.31_encoding@0.1.13_eslint@8.57.0_io_f6286325491df43de002b93d43d737a4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_QJTk6MUGxQ from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.31_encoding@0.1.13_eslint@8.57.0_io_f6286325491df43de002b93d43d737a4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bNGGGFd6i3 from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.31_encoding@0.1.13_eslint@8.57.0_io_f6286325491df43de002b93d43d737a4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_31JSsjklEn from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.2_typescript@5.4.4_vue@3.5.4_typescript@5.4.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/.nuxt/components.plugin.mjs";
import prefetch_client_4yd0cjvb4m from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.31_encoding@0.1.13_eslint@8.57.0_io_f6286325491df43de002b93d43d737a4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_GiYgGPWVxm from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.2_vue@3.5.4_typescript@5.4.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_BeJ4V4sp8o from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.2_vue@3.5.4_typescript@5.4.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import mergeObjects_JbHzNSpMmN from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/plugins/mergeObjects.ts";
import vuetify_DPVY7aMxlt from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/plugins/vuetify.ts";
import notifier_TfOkCLHRTj from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/packages/datakode/nuxt-datakode-utils/plugins/notifier.ts";
import api_client_t0dWRHSNDZ from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/packages/datakode/nuxt-datakode-utils/plugins/api.client.ts";
import currentPath_yqNfcRq2j9 from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/packages/datakode/nuxt-datakode-utils/plugins/currentPath.ts";
import currentUser_28J1aqNENu from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/packages/datakode/nuxt-datakode-utils/plugins/currentUser.ts";
import translation_NgY8VMsLMG from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/packages/datakode/nuxt-datakode-utils/plugins/translation.ts";
import getColor_Ghj8WPsFjF from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/packages/datakode/nuxt-datakode-utils/plugins/getColor.ts";
import helper_Bdxgb2x3zG from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/packages/datakode/nuxt-datakode-utils/plugins/helper.ts";
import formatDate_za3K2nEobv from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/packages/datakode/nuxt-datakode-utils/plugins/formatDate.ts";
import leaflet_client_7dEOJA4hbk from "/builds/9542779116/pildom/APPS/NUXT_ADMIN/plugins/leaflet.client.ts";
export default [
  revive_payload_client_mum5vOjUtA,
  unhead_pwr5MNdm3Z,
  router_Hue9VlnLeT,
  payload_client_oBYI1bUgai,
  navigation_repaint_client_S1y99RSVBq,
  check_outdated_build_client_QJTk6MUGxQ,
  chunk_reload_client_bNGGGFd6i3,
  plugin_vue3_31JSsjklEn,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4yd0cjvb4m,
  switch_locale_path_ssr_GiYgGPWVxm,
  i18n_BeJ4V4sp8o,
  mergeObjects_JbHzNSpMmN,
  vuetify_DPVY7aMxlt,
  notifier_TfOkCLHRTj,
  api_client_t0dWRHSNDZ,
  currentPath_yqNfcRq2j9,
  currentUser_28J1aqNENu,
  translation_NgY8VMsLMG,
  getColor_Ghj8WPsFjF,
  helper_Bdxgb2x3zG,
  formatDate_za3K2nEobv,
  leaflet_client_7dEOJA4hbk
]